<template>
    <div v-if="show">
        <vxe-modal v-model="show" :title="name" width="700" min-width="700" height="330" min-height="330" showFooter
                   esc-closable mask-closable resize>
            <template v-slot>
                <el-form ref="form" size="small" :model="data" label-width="auto" label-position="right" :rules="rules">
                    <el-form-item prop="code" label="车牌号">
                        <el-input v-model="data.code" placeholder="输入车牌号" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="loadWeight" label="车辆载重(吨)">
                        <el-input v-model="data.loadWeight" placeholder="输入车辆载重(吨)" type="number"></el-input>
                    </el-form-item>
                    <el-form-item prop="color" label="车辆颜色">
                        <el-input v-model="data.color" placeholder="输入车辆颜色" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="remark" label="备注">
                        <el-input v-model="data.remark" placeholder="输入备注" type="textarea"></el-input>
                    </el-form-item>
                </el-form>
            </template>
            <template v-slot:footer>
                <div style="text-align: center">
                    <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
                </div>
            </template>
        </vxe-modal>
    </div>
</template>

<script>
export default {
    data() {
        const validateCarCode = (rule, value, callback) => {
            if (value) {
                if (this.$validate.validateCarNum(value)) {
                    callback()
                    this.isCode = true
                } else {
                    callback(new Error('请输入正确的车牌号'))
                    this.isCode = false
                }
            } else {
                callback(new Error('请输入车牌号'))
                this.isCode = false
            }
        }
        return {
            show:false,
            name:'',
            data:{},
            rules:{
                code:[{required:true, trigger:'blur', validator:validateCarCode}],
                loadWeight:[{required:true, trigger:'blur', message:'输入车辆载重'}],
                color:[{required:true, trigger:'blur', message:'输入车辆颜色'}]
            },
            loading:false,
            isDisable:true,
            productType:[]
        }
    },
    methods:{
        /** 打开界面方法 */
        display(data) {
            this.show = true
            this.data = this.$utils.clone(data.data, true)
            this.productType = data.productType
            this.name = data.name
        },
        /** 保存方法 */
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true
                    const params = this.data
                    params['isInside'] = true
                    this.$axios
                        .post('/order/car', params)
                        .then((res) => {
                            if (res) {
                                console.log(this.$utils.isUndefined(this.data.id))
                                if (this.$utils.isUndefined(this.data.id)) {
                                    this.$message.success('新增成功')
                                } else {
                                    this.$message.success('编辑成功')
                                }
                                this.show = false
                                this.$emit('init')
                            }
                            this.loading = false
                        })
                        .catch((err) => {
                            console.log(err)
                            this.loading = false
                        })
                } else {
                    return false
                }
            })
        },
    },
}
</script>