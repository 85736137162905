<template>
    <div class="base-container">
        <vxe-toolbar perfect size="mini">
            <template v-slot:buttons>
                <div class="cf table-header-padding">
                    <div class="fl">
                        <el-button type="primary" @click="getdetail()" size="mini" class="el-icon-plus"
                                   style="margin-right: 10px">
                            新增车辆
                        </el-button>
                        <el-input v-model="search.code" clearable placeholder="输入车牌号" size="mini"
                                  style="width: 120px; margin-right: 10px" @change="searchInit()">
                        </el-input>
                    </div>
                    <div class="fr">
                        <el-tooltip class="item" effect="dark" content="查询" placement="top">
                            <el-button size="mini" @click="searchInit()" circle class="el-icon-search">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="清空" placement="top">
                            <el-button size="mini" @click="search={}; searchInit()" circle class="el-icon-delete">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="导出" placement="top">
                            <el-button @click="exportDataEvent()" size="mini" class="el-icon-download"
                                       circle></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="打印" placement="top">
                            <el-button @click="printDataEvent()" size="mini" circle class="el-icon-printer">
                            </el-button>
                        </el-tooltip>
                    </div>
                </div>
            </template>
        </vxe-toolbar>
        <vxe-table :align="allAlign" :data="data" :height="tableHeight" border :export-config="{}" :print-config="{}"
                   highlight-hover-row auto-resize size="small" ref="vehicle" resizable row-id="id" v-loading="loading"
                   element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" show-header-overflow
                   show-overflow
                   @checkbox-change="changeBoxChange" @checkbox-all="changeBoxChange">
            <template #empty>
                <el-empty></el-empty>
            </template>
            <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
            <vxe-table-column field="code" min-width="120" title="车牌号"></vxe-table-column>
            <vxe-table-column field="color" min-width="150" title="车辆颜色"></vxe-table-column>
            <vxe-table-column field="loadWeight" min-width="150" title="车辆载重(吨)"></vxe-table-column>
            <vxe-table-column field="remark" min-width="150" title="备注"></vxe-table-column>
            <vxe-table-column fixed="right" align="center" width="120" title="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" icon="el-icon-view" @click="getCheck(scope.row)">查看</el-button>
                    <el-button type="text" size="mini" icon="el-icon-edit" @click="getUpdate(scope.row)">编辑
                    </el-button>
                </template>
            </vxe-table-column>
        </vxe-table>
        <vxe-pager perfect size="small" align="left" :loading="loading" :current-page="page.currentPage"
                   :page-size="page.pageSize" :total="page.totalResult" class="ext-pager" selectionChange
                   :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
                   :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]" @page-change="handlePageChange">
            <template v-slot:left>
                <div class="fl footer-check">
                    <p>
                        已选
                        <b style="font-size: 10px">{{ checkLength }}</b> 条
                    </p>
                </div>
                <el-button type="danger" size="mini" icon="el-icon-delete" @click="getDelete()">删除</el-button>
            </template>
        </vxe-pager>
        <check ref="check"/>
        <detail ref="detail" @init="init"/>
    </div>
</template>

<script>
import check from './components/check'
import detail from './components/detail'

export default {
    name:'vehicle',
    data() {
        return {
            data:[],
            tableHeight:window.innerHeight - 190 + 'px',
            allAlign:'center',
            loading:false,
            checkBox:[],
            checkLength:0,
            page:{
                pageSize:20,
                currentPage:1,
                totalResult:0,
            },
            search:{},
            isNull:['', null, undefined],
        }
    },
    created() {
        this.init()
    },
    components:{check, detail},
    mounted() {
        //监听元素变化
        window.onresize = () => {
            if (this.$refs.vehicle) {
                this.tableHeight = window.innerHeight - this.$refs.vehicle.$el.offsetTop - 62 + 'px'
            }
        }
    },
    methods:{
        init() {
            this.loading = true
            const params = {
                params:{
                    pageNum:this.page.currentPage,
                    pageSize:this.page.pageSize
                },
            }
            if (!this.isNull.includes(this.search.code)) params.params.code = this.search.code
            this.$axios
                .get('/order/car/tenantPage', params)
                .then((res) => {
                    if (res) {
                        const data = res.data.data
                        this.data = data.list
                        this.page.currentPage = data.pageNum
                        this.page.totalResult = data.total
                        this.checkBox = []
                        this.checkLength = 0
                    }
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        exportDataEvent() {
            this.$refs.vehicle.openExport()
        },
        printDataEvent() {
            this.$refs.vehicle.openPrint()
        },
        getCheck(row) {
            this.$axios.get(`/order/car/detail/${row.id}`).then((res) => {
                if (res) {
                    let data = {
                        data:res.data.data,
                    }
                    this.$refs.check.display(data)
                }
            })
        },
        getdetail() {
            let data = {
                data:{ },
                name:'新增车辆',
            }
            this.$refs.detail.display(data)
        },
        getUpdate(row) {
            this.$axios.get(`/order/car/detail/${row.id}`).then((res) => {
                let data = {
                    data: res.data.data,
                    name:`编辑${row.code}`,
                }
                this.$refs.detail.display(data)
            })
        },
        /** 选择条数 */
        changeBoxChange() {
            this.checkBox = this.$refs.vehicle.getCheckboxRecords()
            this.checkLength = this.$refs.vehicle.getCheckboxRecords().length
        },
        /** 条数更改 */
        handlePageChange({currentPage, pageSize}) {
            const _this = this
            _this.page.currentPage = currentPage
            _this.page.pageSize = pageSize
            _this.init()
        },
        searchInit() {
            this.page.currentPage = 1
            this.init()
        },
        getDelete() {
            let isOk = false
            if (this.checkLength > 0) {
                isOk = true
            } else {
                isOk = false
                this.$message.warning('请选择至少一条数据')
            }
            if (isOk) {
                const ids = this.checkBox.map((item) => item.id)
                this.$confirm(`确定删除这些数据吗？`, '提示', {
                    confirmButtonText:'确定',
                    cancelButtonText:'取消',
                    type:'warning',
                })
                    .then(() => {
                        this.$axios.post('/order/car/delete', ids).then((res) => {
                            if (res) {
                                this.init()
                                this.$message.success('删除成功')
                            }
                        })
                    })
                    .catch(() => {
                    })
            }
        },
    },
}
</script>

<style scoped></style>
